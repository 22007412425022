import React from 'react'
import './Footer.css'
import  logo from '../../assets/logo.png';

const Footer = () => {
  return (
   <>
   <div className='cFooterWrapper'>
    <hr />
<div className='cFooter'>
  <div className='logo'>
  <img src={logo} alt='/' />
  <span>Glow it</span>

 
    </div>  

    <div className='block'>
      <div className='detail'>
        <span>Contact Us</span>
        <span className='pngLine'>
        <i class="fas fa-map-marker"></i>
        <span>111 north avenue Orlando, FL 32801</span>
        </span>

        <span className='pngLine'>
        <i class="fas fa-phone-alt"></i>
       <a href='tel : 354-4415-605'>354-5515-604</a>
        </span>

        <span className='pngLine'>
        <i class="fas fa-envelope"></i>
       <a href='mailto:support@amazon.css'>support@amazon.com</a>
        </span>
        </div>  
    </div>


    <div className='block'>
      <div className='detail'>
        <span>Account</span>
        <span className='pngLine'>
        <i class="fas fa-user-plus"></i>
        <span>Sign up</span>
        </span>
</div>
</div>


<div className='block'>
      <div className='detail'>
        <span>Company</span>
        <span className='pngLine'>
        <i class="fas fa-address-card"></i>
        <span>About us</span>
        </span>
</div>
</div>


<div className='block'>
      <div className='detail'>
        <span>Resources</span>
        <span className='pngLine'>
        <i class="fas fa-link"></i>
        <span>Safety, Privacy & Terms</span>
        </span>
</div>
</div>
</div>
</div>
   </>
  )
}

export default Footer
