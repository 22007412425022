import React from 'react'
import './hero.css'
import HeroImg from '../../assets/hero.png'
import {motion} from 'framer-motion'
const hero = () => {

  const trans = {duration : 3 , type : "spring"}
  return (
   <>
 <div className='containerH'>
    <div className='h_sides'>
      <span className='text1'>Skin Protection Cream</span>  
    </div>

    <div className='wrapper'>
        <motion.div
        initial= {{bottom: "100px"}}
        whileInView={{bottom : "200px"}}
        className='blueCircle'
        transition = {trans}
        ></motion.div>
        <motion.img 
        transition={trans}
        initial= {{bottom: "-20px"}}
        whileInView={{bottom : "200px"}}
        src={HeroImg} alt='/' width={600}/>
    </div>

    
    </div>

 
   </>
  )
}

export default hero
