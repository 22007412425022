import React from 'react'
import './Tests.css'
import Hero from '../../assets/testimonialHero.png'
import {Swiper, SwiperSlide} from 'swiper/react'
import {TestimonialsData} from '../../data/testimonials'

const Tests = () => {
  return (
    <>
   <div className='test' >
    <div className='wrapper-tests'>
        <div className='container-test'>
     <span>Top Rated</span>  
     <span>
      Speedily say has disposal and and boy.
        </span> 
    </div>

    <img src={Hero} alt='/' />
    <div className='container-hero'>
      <span>100 K</span>
      <span>happy Customers with us</span>
    </div>
   </div>

    <div className='carousel'>
        <Swiper
        
        slidesPerView={3} spaceBetween={20} 
        sliderPerGroup={1} loop ={true}
        className='tCarousel'>
            {
             TestimonialsData.map((TestimonialsData ,i) =>{
                return (
                    <SwiperSlide>
                   <div className='tests'>
                    <span>{TestimonialsData.comment}</span>
                    <span>{TestimonialsData.name}</span>

                    </div>     
                    </SwiperSlide>
                )
             })
            }
        </Swiper>
    </div>

   </div>
    </>
  )
}

export default Tests
