import React from 'react'
import './wrap.css'
const Wrapp = () => {

    const data = [
        {
            cover: <i class='fa-solid fa-truck-fast'></i>,
            title: "Worldwide Delivery",
            decs: "We offer competitive prices on our 100 million plus product any range.",
          },
          {
            cover: <i class='fa-solid fa-id-card'></i>,
            title: "Safe Payment",
            decs: "We offer competitive prices on our 100 million plus product any range.",
          },
          {
            cover: <i class='fa-solid fa-shield'></i>,
            title: "Shop With Confidence ",
            decs: "We offer competitive prices on our 100 million plus product any range.",
          },
          {
            cover: <i class='fa-solid fa-headset'></i>,
            title: "24/7 Support ",
            decs: "We offer competitive prices on our 100 million plus product any range.",
          },  
    ]
  return (
   <>
   <section className='wrapper back'>
    <div className='grid'>
        {data.map((val,index) =>{
            return(
                    <div className='product-w' key={index}>
                         <div className='img icon-circle'>
                  <i>{val.cover}</i>
                 
                </div>
               <h3>{val.title}</h3>
               <p>{val.decs}</p>
                        </div>
                       
            )
        })}
    </div>
   </section>
   </>
  )
}

export default Wrapp
