import React from 'react';
import './Header.css';
import { Link } from "react-router-dom"
import  logo from '../../assets/logo.png';
const header = () => {
  return (
   <>
   <div className='container' >
    <div className='logo'>
    <img src={logo} alt='/' />
    <span>Glow it</span>
    </div>
    <div className='right'>
        <div className='menu'>
        <ul className='menu'>
        <li>
              <Link to='/'>
             Home
              </Link>
              </li>
        <li>Collections</li>
        <li>New</li>
        <li>Sales</li>
        <li>Contact</li>
        <li>
              <Link to='/cart'>
                <i className='fa fa-shopping-bag icon-circle'></i>
              </Link>
              </li>
        </ul>
        </div>
    </div>
    </div>
   </>
  )
}

export default header
