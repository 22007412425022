import React from 'react'
import './subscribe.css'

const Subscribe =() => {
 

    return (
     <div className='sub'>
      <div className='info'>
        <span>Register and get up to 10% off</span>
        <span>Be the first to know about our best deals!</span>
      </div>
      <div className='send'>
       <input
        type="email"
        placeholder="enter your email"
      />
        <button type="submit" 
        className='btn' >Register</button>
      </div>
     </div>
    )
  }

export default Subscribe
