import React , {useState} from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Pages from "./Pages/pages";
import './App.css';
import Header from './components/header/header'
import Cart from  './components/Cart/cart'
import './index.css'
import { SliderProducts } from "./data/Products";
import { ProductsData } from "./data/Products";

function App() {

  const { Products } = SliderProducts ;
  const {Shop} = ProductsData ;
  const [CartItem, setCartItem] = useState([])

 
  const addToCart = (Products) => {
    // if hamro product alredy cart xa bhane  find garna help garxa
    const productExit = CartItem.find((slide) => slide.name === Products.name)
    // if productExit chai alredy exit in cart then will run fun() => setCartItem
    // ani inside => setCartItem will run => map() ani yo map() chai each cart ma
    // gayara check garxa if item.id ra product.id chai match bhayo bhane
    // productExit product chai display garxa
    // ani increase  exits product QTY by 1
    // if item and product doesnt match then will add new items
    if (productExit) {
      setCartItem(CartItem.map((slide) => (slide.name === Products.name ? { ...productExit, qty: productExit.qty + 1 } : slide)))
    } else {
      // but if the product doesnt exit in the cart that mean if card is empty
      // then new product is added in cart  and its qty is initalize to 1
      setCartItem([...CartItem, { ...Products, qty: 1 }])
    }
  }

  // Stpe: 6
  const decreaseQty = (Products) => {
    const productExit = CartItem.find((slide) => slide.name === Products.name)
    if (productExit.qty === 1) {
      setCartItem(CartItem.filter((slide) => slide.name !== Products.name))
    } else {
      
      setCartItem(CartItem.map((slide) => (slide.name === Products.name ? { ...productExit, qty: productExit.qty - 1 } : slide)))
    }
  }

  return (
   <>



        <Router>
        <Header  CartItem = {CartItem} />
        <Switch>
          <Route path='/' exact>
          <Pages Products={Products} Shop={Shop} addToCart={addToCart}  />
          </Route>
          <Route path='/cart' exact>
            <Cart CartItem={CartItem} addToCart={addToCart} decreaseQty={decreaseQty}/>
          </Route>
        </Switch>
      
      </Router>



   </>
  )
}
  
export default App 
