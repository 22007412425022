import React  , {useState} from 'react'
import './ItemsStyle.css'
import Plane from '../../assets/plane.png' 
import {ProductsData} from '../../data/Products'
import { useAutoAnimate } from '@formkit/auto-animate/react'
const Items = ({addToCart}) => {

  const [parent] = useAutoAnimate ()

  const [MenuProducts , setMenuProducts ] = useState(ProductsData)

  const filter = (type) =>{
    setMenuProducts(ProductsData.filter((product) =>product.type === type))
  }
  return (
   <>
   <div className='containerP'>
    <img src={Plane} alt='/' />
    <h1>Our Featured Products</h1>

    <div className='productsP'>
      <ul className='menuP'>
        <li>All</li>
        <li onClick={() =>filter("skin care")}>Skin Care</li>
        <li onClick={() =>filter("conditioner")}>Conditioners</li>
        <li onClick={() =>filter("foundation")}>Foundations</li>
      </ul>

      <div className='list' ref={parent}>
      {
        MenuProducts.map((ProductsData , i) =>{
          return (
            <div className='product-pp'>
             <div className='left-ss'>
              <div className='name'>
                <span>{ProductsData.name}</span>
                <span>{ProductsData.detail}</span>
                </div>
                <span>${ProductsData.price}</span>
                <button onClick = {() => addToCart(ProductsData)} >Shop now</button>
              </div>

              <img src={ProductsData.img} alt='/' className='img-pp'/>
              </div>
          )
        })
      }
      </div>
    </div>
   </div>
   </>
  )
}

export default Items
