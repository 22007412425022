import React from 'react'
import './slider.css'
import {Swiper,SwiperSlide} from 'swiper/react'
import {Pagination , Navigation} from "swiper"
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import {SliderProducts} from '../../data/Products'
const slider = ({addToCart}) => {
  return (
   <>
  <div className='s-container'>
    <Swiper 
    modules = {{Pagination , Navigation}}
    className = "mySwiper"
    
    loopFillGroupWithBlank = {true}
    slidesPerView={3} spaceBetween={40} 
    sliderPerGroup={1} loop ={true}>
     {SliderProducts.map((SliderProducts) =>{
      return(
      <SwiperSlide>
      <div className='left-s'>
      <div className='name'>
          <span>{SliderProducts.name}</span>
          <span>{SliderProducts.detail}</span>
      </div>
      <span>${SliderProducts.price}</span>
      <button onClick = {() => addToCart(SliderProducts)} >Shop now</button>
      </div>
      <img src={SliderProducts.img} alt="product" className='img-p' />
      </SwiperSlide>
      )
     })} 
   
    </Swiper>
  </div>
   </>
  )
}

export default slider

/*
<SwiperSlide>
<div className='left-s'>
    <div className='name'>
        <span>{SliderProducts.name}</span>
        <span>{SliderProducts.detail}</span>

    </div>
    <span>${SliderProducts.price}</span>
    <div>Shop now</div>
</div>
<img src={SliderProducts.img} alt="product" className='img-p' />
</SwiperSlide>
*/