import React from 'react'
import './virtual.css'
import Shade from '../../assets/shade.png'
import ReactCompareImage from 'react-compare-image';
import Before from '../../assets/before.png'
import After from '../../assets/after.png'
const virtual = () => {
  return (
    <>
   <div className='virtual'>
    <div className='left'>
        <span>Virtual Try-On</span>
        <span>Never Buy the wrong Shade Again!</span>
        <span>Try Now !</span>
        <img src={Shade} alt="/" />
    </div>

    <div className='right'>
   <div className='wrapperImg'>
   <ReactCompareImage  leftImage={Before}
    rightImage={After}/>
   </div>
    </div>
    </div>
    </>
  )
}

export default virtual
