import React from 'react'
import Wrapper from '../components/wrapper/Wrapp';
import Hero from '../components/Hero/hero'
import Items from '../components/Items/items';
import Slider from '../components/Slider/slider';
import Virtual  from '../components/Virtual/virtual';
import Tests from '../components/Testimonials/Tests';
import Footer from '../components/Footer/Footer';
import Subscribe from '../components/Subs/Subscribe';
const pages = ({Products , Shop ,CartItem , addToCart}) => {
  return (
    <>
    <Hero  CartItem={CartItem}/>
    <Slider  Product = {Products} Shop={Shop} addToCart={addToCart} />
    <Virtual />
   <Items Shop={Shop} addToCart={addToCart}  />
   <Tests />
   <Wrapper />
   <Subscribe />
   <Footer />

    </>
  )
}

export default pages
